import { Component, OnInit,ViewChild } from '@angular/core';
import { HttpClient,HttpHeaders,HttpParams,HttpResponse, HttpXhrBackend } from '@angular/common/http';
import { GlobaldataService } from '../../servicios/globaldata.service';
import { UtilService } from '../../servicios/util.service';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import { AsuntoComponent } from '../../componentes/asunto/asunto.component';
import {MisspunchSolicitudesComponent} from '../../empleados/misspunch-solicitudes/misspunch-solicitudes.component';
import {SolicitudesComponent} from '../../empleados/solicitudes/solicitudes.component';
import { ConfirmdocumentComponent } from 'src/app/documentos/confirmdocument/confirmdocument.component';
import {DialogService} from 'primeng/dynamicdialog';
import { SolicitudInterceptor } from '../../interceptores/solicitud.interceptor';
 
@Component({
  selector: 'app-recientes',
  templateUrl: './recientes.component.html',
  styleUrls: ['./recientes.component.scss']
})
export class RecientesComponent implements OnInit {

	@ViewChild('asunto') asunto:AsuntoComponent;  
	recientes:any=[];
	publicaciones:any=[];
	asuntos:any=[];
	colaboratorsList:any =[]



	idClient_collaborator:any ="";
    hidemenu: string = "";
	access_documents_recent:any ="";
    access_documents_senddocument:any ="";
    access_documents_mydocument:any ="";
    access_documents_taxes:any ="";
    access_consultation:any ="";
    access_issues:any ="";
    access_information:any ="";
    access_labor_incidences:any ="";
    access_labor_names:any ="";
    access_labor_payrollsummery:any ="";
    access_labor_docemployees:any ="";
    access_labor_paidgrandchildren:any ="";
    access_labor_employeeportal:any ="";
    access_labor_request:any ="";
    access_labor_entryexit_request:any ="";
    access_labor_time:any ="";
    access_labor_checkpermissions:any ="";
    access_labor_globalquery:any ="";
    access_labor_complaintsportal:any ="";
    access_mydata:any ="";
    access_collaborator:any ="";
    access_mylatest_documents:any =""; 
    access_mylatest_affairs:any =""; 
	api_token: string="";

	constructor(private httpClient: HttpClient,public gds:GlobaldataService,private us:UtilService,public dialogService:DialogService) { }
		
	
	ngOnInit() {


		this.idClient_collaborator = this.gds.idClient_collaborator;
    this.hidemenu = 'hidemenu';

        this.access_documents_recent = this.gds.access_documents_recent;
        this.access_documents_senddocument = this.gds.access_documents_senddocument;
        this.access_documents_mydocument = this.gds.access_documents_mydocument;
        this.access_documents_taxes = this.gds.access_documents_taxes;
        this.access_consultation = this.gds.access_consultation;
        this.access_issues = this.gds.access_issues;
        this.access_information = this.gds.access_information;
        this.access_labor_incidences = this.gds.access_labor_incidences;
        this.access_labor_names = this.gds.access_labor_names;
        this.access_labor_payrollsummery = this.gds.access_labor_payrollsummery;
        this.access_labor_docemployees = this.gds.access_labor_docemployees;
        this.access_labor_paidgrandchildren = this.gds.access_labor_paidgrandchildren;
        this.access_labor_employeeportal = this.gds.access_labor_employeeportal;
        this.access_labor_request = this.gds.access_labor_request;
        this.access_labor_entryexit_request = this.gds.access_labor_entryexit_request;
        this.access_labor_time = this.gds.access_labor_time;
        this.access_labor_checkpermissions = this.gds.access_labor_checkpermissions;
        this.access_labor_globalquery = this.gds.access_labor_globalquery;
        this.access_labor_complaintsportal = this.gds.access_labor_complaintsportal;
        this.access_mydata = this.gds.access_mydata;
        this.access_collaborator = this.gds.access_collaborator;
        this.access_mylatest_documents = this.gds.access_mylatest_documents;
        this.access_mylatest_affairs = this.gds.access_mylatest_affairs;
        this.idClient_collaborator = this.gds.idClient_collaborator;

        console.log("access_documents_recent",this.access_documents_recent);
        console.log("access_mylatest_documents",this.access_mylatest_documents);
        console.log("access_mylatest_affairs",this.access_mylatest_affairs);











	
		
	this.httpClient.post(this.gds.urlBaseDL+'multiple',
	[
		{"method":"get","url":"documentos","params":{
			"order":"fechaIncorporacion DESC",
			"fields":'[idDocumento,descripcion,visto,fechaIncorporacion,restricted]',
			"limit":15
			,"where":"idCliente="+this.gds.id_cliente
		}},
		{"method":"get","url":"documentos","params":{
			"order":"fechaIncorporacion DESC",
			"fields":'[idDocumento,descripcion,fechaIncorporacion,restricted]',
			"limit":4
			,"where":"idCliente=5001 and idMateria=200"
		}},
		{"method":"get","url":"tareas","params":{
			"order":"fecha DESC,idTarea DESC",
			"limit":15
			,"where":"idCliente="+this.gds.id_cliente
		}}
	]
	).subscribe((res)=>{
		console.log(res);
		this.recientes=res[0]['resp'];
		this.publicaciones=res[1]['resp'];
		this.asuntos=res[2]['resp'];
		
		this.publicaciones.map(p=>{
			p[1]=p[1].replace(/_/g," ");
			return p;
		})
	});

	// this.httpClient.post("http://46.4.45.133:8080/oficinaDigital/dispacher?form=ServicesIO&accion=getColaboratorsList&idCliente="+this.gds.id_cliente,{}).
	// subscribe((res)=>{
	// 	this.colaboratorsList=res;
	// });
	this.httpClient.post("https://portalasesoria.oficinadigital.eu/oficinaDigital/dispacher?form=OficinaDigitalAPI&accion=login",{idCliente:this.gds.id_cliente,cif:this.gds.collaborator_cif}).
	subscribe((res)=>{
		  const httpClient = new HttpClient(new HttpXhrBackend({ build: () => new XMLHttpRequest() }));
		  const headers = new HttpHeaders({
			Authorization: `Bearer ${res['data']['token']}`, // Add your token here
		  });
		  const apiUrl = 'https://portalasesoria.oficinadigital.eu/oficinaDigital/dispacher?form=OficinaDigitalAPI&accion=getColaboratorsList'; 
		  httpClient.get(apiUrl, { headers }).subscribe(
			(response) => {
			 this.colaboratorsList = response['data']
			},
			(error) => {
			  console.error('Error:', error);
			}
		  );
	});
  }
  
  
  
  trackById(index, contact) {
	  console.log(contact[0]);
	return contact[0];
  }
  
  verTramite(ev)
  {
	  var id:Number=this.gds.getID(ev);
	  if(id>0) this.asunto.show(id);
	  return false;
  }


  openUrlForColaborator(url){

	//alert(url);
	if(url!='')
	{
		//window.location.href = url;
		window.open(
			url,
			'_blank' 
		  );
	}
	
  }

	selectMisDocument(event){

		//alert("sss");

		console.log(event);

    let id = event.target.attributes['data-id'].value;
    let restricted = event.target.attributes['data-restricted'].value;

		console.log("restricted--",restricted);
		if(restricted=='1')
		{

			
			this.httpClient.get(this.gds.urlBaseDL + 'updaeCodeDocumentos/getDocumentosId/' + id+'/'+this.gds.id_cliente).toPromise().then(async (r) => {
				//console.log(r.resp);
		  
				this.httpClient.get(this.gds.urlBaseDL + 'sendWhatzappForDownloadDocumentos/getDocumentosId/' + id+'/'+this.gds.id_cliente).toPromise();
				
			  });


			    //alert("sss");
					const ref = this.dialogService.open(ConfirmdocumentComponent, {
					  data:{id:id,confirmevent:event,confirmtype:'recent'},
					  header: 'Documento protegido',
					  width: '70%' 
				  });

				  

			
		}
		else
		{
			this.gds.viewDoc(event);
      
		}


	
	  }








}




