import { Component, OnInit } from '@angular/core';
import { GlobaldataService } from '../../servicios/globaldata.service';
import { HttpClient,HttpHeaders,HttpParams,HttpResponse } from '@angular/common/http';
import { Respuesta } from '../../respuesta';
import {formatDate} from '@angular/common';
//import {Solicitud,Documento} from '../libs/dataObjects'
import {MessageService} from 'primeng/api';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-solicitudes',
  templateUrl: './solicitudes.component.html',
  styleUrls: ['./solicitudes.component.scss']
})
export class SolicitudesComponent implements OnInit { 

  solicitudes:any[]=[];
  disfrutados={};
  disfrutadosArr={};
  daysctn={};
  http: any;
  solicitudescada:any[]=[];

  idClient_collaborator:any ="";
  hidemenu: string = "";
access_documents_recent:any ="";
  access_documents_senddocument:any ="";
  access_documents_mydocument:any ="";
  access_documents_taxes:any ="";
  access_consultation:any ="";
  access_issues:any ="";
  access_information:any ="";
  access_labor_incidences:any ="";
  access_labor_names:any ="";
  access_labor_payrollsummery:any ="";
  access_labor_docemployees:any ="";
  access_labor_paidgrandchildren:any ="";
  access_labor_employeeportal:any ="";
  access_labor_request:any ="";
  access_labor_entryexit_request:any ="";
  access_labor_time:any ="";
  access_labor_checkpermissions:any ="";
  access_labor_globalquery:any ="";
  access_labor_complaintsportal:any ="";
  access_mydata:any ="";
  access_collaborator:any ="";
  access_mylatest_documents:any ="";
  access_mylatest_affairs:any ="";








  constructor(public gds:GlobaldataService,private messageService: MessageService,private httpClient: HttpClient) { }

  ngOnInit(): void {
    this.loadDisfrutados();
    this.loadSolicitudes();
    this.loadSolicitudescada();


    this.idClient_collaborator = this.gds.idClient_collaborator;


        this.access_documents_recent = this.gds.access_documents_recent;
        this.access_documents_senddocument = this.gds.access_documents_senddocument;
        this.access_documents_mydocument = this.gds.access_documents_mydocument;
        this.access_documents_taxes = this.gds.access_documents_taxes;
        this.access_consultation = this.gds.access_consultation;
        this.access_issues = this.gds.access_issues;
        this.access_information = this.gds.access_information;
        this.access_labor_incidences = this.gds.access_labor_incidences;
        this.access_labor_names = this.gds.access_labor_names;
        this.access_labor_payrollsummery = this.gds.access_labor_payrollsummery;
        this.access_labor_docemployees = this.gds.access_labor_docemployees;
        this.access_labor_paidgrandchildren = this.gds.access_labor_paidgrandchildren;
        this.access_labor_employeeportal = this.gds.access_labor_employeeportal;
        this.access_labor_request = this.gds.access_labor_request; 
        this.access_labor_entryexit_request = this.gds.access_labor_entryexit_request;
        this.access_labor_time = this.gds.access_labor_time;
        this.access_labor_checkpermissions = this.gds.access_labor_checkpermissions;
        this.access_labor_globalquery = this.gds.access_labor_globalquery;
        this.access_labor_complaintsportal = this.gds.access_labor_complaintsportal;
        this.access_mydata = this.gds.access_mydata;
        this.access_collaborator = this.gds.access_collaborator;
        this.access_mylatest_documents = this.gds.access_mylatest_documents;
        this.access_mylatest_affairs = this.gds.access_mylatest_affairs;
        this.idClient_collaborator = this.gds.idClient_collaborator;

        console.log("access_documents_recent",this.access_documents_recent);
        console.log("access_mylatest_documents",this.access_mylatest_documents);
        console.log("access_mylatest_affairs",this.access_mylatest_affairs);
  }




  loadSolicitudes()
  {
    let url2='solicitudes?'
    +'&fields=idSolicitud,T.empleado as empleado,E.idEmpleado,idtipopermiso,solicitud,fechainicio,fechafin,horainicio,horafin,fechas,estado,doc,year(fechainicio) as ano'
    +'&joins=!{empleado}(S.idEmpleado),!{trabajador}(E.idTrabajador)'
    +"&where=estado='SOLICITUD' and E.idCliente="+this.gds.id_cliente
    +'&order=fechainicio';

    this.httpClient.get<Respuesta>(this.gds.urlBaseDL+url2).toPromise().then((r)=>{
      for(let p of r.resp)
      {
        p['motivo']="";
        let cd=p.fechas.split(",").length;
        p['dias']=cd; 
        if (cd>1)
          p['periodo']=formatDate(p.fechainicio,'dd/MM/yyyy','en-US')+" - "+formatDate(p.fechafin,'dd/MM/yyyy','en-US');
        else
          p['periodo']=formatDate(p.fechainicio,'dd/MM/yyyy','en-US');

        if(p.horainicio!=null && p.horafin!=null)
        {
          p['periodo']+=" "+p.horainicio.substr(0,5)+"-"+p.horafin.substr(0,5);
        }
      }
      console.log(r.resp);
      this.solicitudes=r.resp;
    });
  }


  loadSolicitudescada()
  {
    let url2='solicitudescada?'
    +'&fields=idSolicitudescada,idSolicitud,T.empleado as empleado,E.idEmpleado,solicitud,fecha,estado,year(fecha) as ano'
    +'&joins=!{empleado}(S.idEmpleado),!{trabajador}(E.idTrabajador)'
    //+"&where=estado='SOLICITUD' and E.idCliente="+this.gds.id_cliente
    +"&where=estado='SOLICITUD' and E.idCliente="+this.gds.id_cliente
    //+"&where=estado='SOLICITUD' "
    +'&order=idSolicitudescada';

    this.httpClient.get<Respuesta>(this.gds.urlBaseDL+url2).toPromise().then((r)=>{
      for(let p of r.resp)
      {
        p['motivo']="";
        let cd=p.fecha.split(",").length;
        p['dias']=cd; 
        p['periodo']=formatDate(p.fecha,'dd/MM/yyyy','en-US');
        // if (cd>1)
        //   p['periodo']=formatDate(p.fechainicio,'dd/MM/yyyy','en-US')+" - "+formatDate(p.fechafin,'dd/MM/yyyy','en-US');
        // else
        //   p['periodo']=formatDate(p.fechainicio,'dd/MM/yyyy','en-US');

        // if(p.horainicio!=null && p.horafin!=null)
        // {
        //   p['periodo']+=" "+p.horainicio.substr(0,5)+"-"+p.horafin.substr(0,5);
        // }
      }
      console.log("eeeeeeeeeeeeeeeeeeeeeeee",r.resp);
      this.solicitudescada=r.resp;
    });
  }



  loadDisfrutados_old() 
  {
    //alert(this.gds.ejercicio_actual);
    let url2='solicitudes?'
    +'&fields=idSolicitud,T.empleado as empleado,E.idEmpleado as idEmpleado,idtipopermiso,solicitud,fechainicio,fechafin,horainicio,horafin,fechas,estado,doc,year(fechainicio) as ano'
    +'&joins=!{empleado}(S.idEmpleado),!{trabajador}(E.idTrabajador)'
    +"&where=estado='ACEPTADA' and idtipopermiso!=0 and year(fechainicio)="+this.gds.ejercicio_actual+" and E.idCliente="+this.gds.id_cliente;

    this.httpClient.get<Respuesta>(this.gds.urlBaseDL+url2).toPromise().then((r)=>{
      this.disfrutados={};
      for(let p of r.resp)
      {
        let cd=p.fechas.split(",").length;
        let key=p['idEmpleado']+"|"+p['idtipopermiso']+"|"+p['ano'];
        if (key in this.disfrutados)
          this.disfrutados[key]=this.disfrutados[key]+cd
        else
          this.disfrutados[key]=cd
      }
      console.log("dayssssssssssssssssssssss",this.disfrutados);
    });




  }



  loadDisfrutados() 
  {
    //alert(this.gds.ejercicio_actual);
    let url2='solicitudes?'
    +'&fields=idSolicitud,T.empleado as empleado,E.idEmpleado as idEmpleado,idtipopermiso,solicitud,fechainicio,fechafin,horainicio,horafin,fechas,estado,doc,year(fechainicio) as ano'
    +'&joins=!{empleado}(S.idEmpleado),!{trabajador}(E.idTrabajador)'
    +"&where=estado='ACEPTADA' and idtipopermiso!=0 and year(fechainicio)="+this.gds.ejercicio_actual+" and E.idCliente="+this.gds.id_cliente;

    this.httpClient.get<Respuesta>(this.gds.urlBaseDL+url2).toPromise().then((r)=>{
      this.disfrutados={};
      this.disfrutadosArr={};
      for(let p of r.resp)
      {
        let cd=p.fechas.split(",").length;

        //////////////////////////newli added////////////////////////////////

        let keyArr=p['idEmpleado']+"|"+p['ano'];
        if (keyArr in this.disfrutadosArr)
          this.disfrutadosArr[keyArr]=this.disfrutadosArr[keyArr]+cd
        else
          this.disfrutadosArr[keyArr]=cd

//////////////////////////newli added end////////////////////////////////


        let key=p['idEmpleado']+"|"+p['idtipopermiso']+"|"+p['ano'];
        if (key in this.disfrutados)
          this.disfrutados[key]=this.disfrutados[key]+cd
        else
          this.disfrutados[key]=cd



          
      }
      console.log("dayssssssssssssssssssssss",this.disfrutados);
      console.log("sopppppppppppp",this.disfrutadosArr);
    });




  }


  getDisfrutados(idEmpleado,idTipoPermiso,año)
  {
    //alert(idEmpleado+"|"+idTipoPermiso+"|"+año);
    // console.log("dddddddddddddddddkkkkkkpppp",idEmpleado+"|"+idTipoPermiso+"|"+año);
    // let key=idEmpleado+"|"+idTipoPermiso+"|"+año;
    // if (key in this.disfrutados)
    //   return this.disfrutados[key];
    // else
    //   return 0;
//////////////////////////newli added////////////////////////////////
    console.log("wokooooooooooo",this.disfrutadosArr);
    console.log("rokooooooo",idEmpleado+"|"+año);
    let key=idEmpleado+"|"+año;
    if (key in this.disfrutadosArr)
      return this.disfrutadosArr[key];
    else
      return 0;
//////////////////////////newli added end////////////////////////////////
  }


  getDisfrutados_old(idEmpleado,idTipoPermiso,año)
  {


    ////////////////////////////////////////////////////////////////////////////
    //alert(idEmpleado+"|"+idTipoPermiso+"|"+año);
    console.log("dddddddddddddddddkkkkkkmm",idEmpleado+"|"+idTipoPermiso+"|"+año);
    let key=idEmpleado+"|"+idTipoPermiso+"|"+año;
    if (key in this.disfrutados)
      return this.disfrutados[key];
    else
      return 0; 
  }


  


  getDayCount(idEmpleado,idTipoPermiso,año)
  {
//alert(this.gds.ejercicio_actual);


    let url2='solicitudes?'
    +'&fields=idSolicitud,T.empleado as empleado,E.idEmpleado as idEmpleado,idtipopermiso,solicitud,fechainicio,fechafin,horainicio,horafin,fechas,estado,doc,year(fechainicio) as ano'
    +'&joins=!{empleado}(S.idEmpleado),!{trabajador}(E.idTrabajador)'
    +"&where=estado='ACEPTADA' and E.idEmpleado in ("+idEmpleado+") and idtipopermiso!=0 and year(fechainicio)="+this.gds.ejercicio_actual+" and E.idCliente="+this.gds.id_cliente;


     


    return  this.httpClient.get(this.gds.urlBaseDL + url2).toPromise();
  


  }


 

  async aceptar(i)
  {
    //console.log(this.solicitudes[i].fechas);
    let hora_inicio=this.solicitudes[i].horainicio;
    let hora_fin=this.solicitudes[i].horafin;
    let fics=[];
    for(let dia of this.solicitudes[i].fechas.split(","))
    {
        let fecha=this.solicitudes[i].fechainicio.substr(0,4);
        fecha+="-"+dia.substr(3,2)+"-"+dia.substr(0,2);
        let fecha_inicio=fecha;
        let fecha_fin=fecha;
        if (hora_inicio!=null && hora_fin!=null)
        {
           fecha_inicio+=" "+hora_inicio;
           fecha_fin+=" "+hora_fin;
        }
        //console.log(fecha_inicio,fecha_fin);
        let fic={inicio:fecha_inicio,fin:fecha_fin,idempleado:this.solicitudes[i].idEmpleado,
                 tipo:'permiso',detalle:this.solicitudes[i].solicitud};
        fics.push(fic);
    }
    console.log(fics);
    await this.httpClient.post<Respuesta>(this.gds.urlBaseDL+'horario',fics).toPromise().then((r)=>{
      console.log(r);
    });
    this.procesarSolicitud(i,"ACEPTADA");
    this.loadDisfrutados();
  }

  rechazar(i)
  {
    this.procesarSolicitud(i,"RECHAZADA");
  }
  
  procesarSolicitud(i,estado)
  {
    let id=this.solicitudes[i]['idSolicitud'];
    this.gds.updateSolicitud(id,{estado:estado,contestacion:this.solicitudes[i]['motivo']}).then((r)=>{
      console.log(r);
    });
    let idEmpleado=this.solicitudes[i]['idEmpleado'];
    let body="Estimado "+this.solicitudes[i]['empleado']+":\n\n"
              +"Su petición <<"+this.solicitudes[i]['solicitud']+">> para los días: "+this.solicitudes[i]['fechas']
              +" ha sido "+estado+".\n\n";
    if(this.solicitudes[i]['motivo']!="") body+="Motivo: "+this.solicitudes[i]['motivo']+".";
    body+="\n\nUn saludo\n\n"+this.gds.name_cliente;
    this.gds.emailToEmpleado(idEmpleado,"Solicitud "+estado,body).then((r)=>{
      console.log(r);
    });
    
    if (estado=='ACEPTADA')
    {
      let body2=`La empresa ${this.gds.name_cliente} ha aceptado la petición de permiso o licencia:\n\n`;
      body2+=`<< ${this.solicitudes[i]['solicitud']} >>, realizada por el trabajador: ${this.solicitudes[i]["empleado"]}, para las fechas: ${this.solicitudes[i]['fechas']}\n\nSaludos`;
      console.log(body2);
      // "Estimado "+this.solicitudes[i]['empleado']+":\n\n"
      //         +"Su petición <<"+this.solicitudes[i]['solicitud']+">> para los días: "+this.solicitudes[i]['fechas']
      //         +" ha sido "+estado+".\n\n";
      // if(this.solicitudes[i]['motivo']!="") body2+="Motivo: "+this.solicitudes[i]['motivo']+".";
      // body2+="\n\nUn saludo\n\n"+this.gds.name_cliente;


      // this.gds.emailToLaboral("Solicitud Permiso/Licencia "+estado,body2).then((r)=>{
      //   console.log(r);
      // });


    }
    this.solicitudes.splice(i,1); 
  }

























  procesarSolicitudcada(k,estado)
  {

    console.log("zzzzzzzzzzzzzzz",this.solicitudescada);
    let idSolicitud=this.solicitudescada[k]['idSolicitud'];
    let idSolicitudescada=this.solicitudescada[k]['idSolicitudescada'];
    let fecha=this.solicitudescada[k]['fecha'];
    fecha=formatDate(fecha,'dd/MM','en-US');

    //alert(idSolicitud+"---"+fecha+"---"+this.solicitudescada[k]['motivo']);

    if (estado=='ACEPTADA')
    {

      let url2='solicitudes?'
    +'&fields=idSolicitud,T.empleado as empleado,E.idEmpleado as idEmpleado,idtipopermiso,solicitud,fechainicio,fechafin,horainicio,horafin,fechas,estado,doc,year(fechainicio) as ano'
    +'&joins=!{empleado}(S.idEmpleado),!{trabajador}(E.idTrabajador)'
    +"&where=idsolicitud="+idSolicitud;

      this.httpClient.get<Respuesta>(this.gds.urlBaseDL+url2).toPromise().then((r)=>{
      let fechas='';
      let fechainicio='';
      let fechafin='';
      for(let p of r.resp)
      {
      fechas=p.fechas;
      fechainicio=p.fechainicio;
      fechafin=p.fechafin;
      }
      console.log(r.resp);


      let fechainicioItem=fechainicio;
      // if(fechainicio==fecha)
      // {
      //   //formatDate(p.fechainicio,'dd/MM/yyyy','en-US')
      //   var date = new Date(fechainicio);
      //   let fechainicioItem=date.setDate(date.getDate() + 1);

      // }
      

      let fechafinItem=fechafin;
      //  if(fechafin==fecha)
      // {
      //   //formatDate(p.fechainicio,'dd/MM/yyyy','en-US')
      //   var date = new Date(fechafin);
      //   let fechafinItem=date.setDate(date.getDate() - 1);
      // }
      

      console.log("fechainicio",fechainicioItem);
      console.log("fechafin",fechafinItem);
      
      let searchfecha1=fecha+","
      let searchfecha2=","+fecha;
      let searchfecha3=fecha;

      if(fechas.indexOf(searchfecha1)!=-1)
      {

        let fechasResult = fechas.replace(searchfecha1, "");
        console.log("fechas",fechasResult);

        this.gds.updateSolicitudNew(idSolicitud,{fechainicio:fechainicioItem,fechafin:fechafinItem,fechas:fechasResult}).then((r)=>{
          console.log(r);
          });
      }
      else if(fechas.indexOf(searchfecha2)!=-1)
      {
        let fechasResult = fechas.replace(searchfecha2, "");
        this.gds.updateSolicitudNew(idSolicitud,{fechainicio:fechainicioItem,fechafin:fechafinItem,fechas:fechasResult}).then((r)=>{
          console.log(r);
          });
      }
      else if(fechas==searchfecha3)
      {
        let fechasResult = fechas.replace(searchfecha3, "");
        this.gds.updateSolicitudNew(idSolicitud,{fechainicio:fechainicioItem,fechafin:fechafinItem,fechas:fechasResult,estado:"CANCELADO"}).then((r)=>{
          console.log(r);
          });
      }


      

      });


   
      

    }
    
    this.gds.updateSolicitudCada(idSolicitudescada,{estado:estado,contestacion:this.solicitudescada[k]['motivo']}).then((r)=>{
      console.log(r);
    }); 



    let idEmpleado=this.solicitudescada[k]['idEmpleado'];
    let body="Estimado "+this.solicitudescada[k]['empleado']+":\n\n"
              +"Su petición <<"+this.solicitudescada[k]['solicitud']+">> para los días: "+this.solicitudescada[k]['fecha']
              +" ha sido "+estado+".\n\n";
    //if(this.solicitudes[k]['motivo']!="") body+="Motivo: "+this.solicitudes[k]['motivo']+".";
    body+="\n\nUn saludo\n\n"+this.gds.name_cliente;
    this.gds.emailToEmpleado(idEmpleado,"Solicitud "+estado,body).then((r)=>{
      console.log(r);
    });
    
    if (estado=='ACEPTADA')
    {
 

      let body2=`La empresa ${this.gds.name_cliente} ha aceptado la petición de permiso o licencia:\n\n`;
      body2+=`<< ${this.solicitudescada[k]['solicitud']} >>, realizada por el trabajador: ${this.solicitudescada[k]["empleado"]}, para las fechas: ${this.solicitudescada[k]['fecha']}\n\nSaludos`;
      console.log(body2);
      // "Estimado "+this.solicitudes[i]['empleado']+":\n\n"
      //         +"Su petición <<"+this.solicitudes[i]['solicitud']+">> para los días: "+this.solicitudes[i]['fechas']
      //         +" ha sido "+estado+".\n\n";
      // if(this.solicitudes[i]['motivo']!="") body2+="Motivo: "+this.solicitudes[i]['motivo']+".";
      // body2+="\n\nUn saludo\n\n"+this.gds.name_cliente;
      this.gds.emailToLaboral("Solicitud Permiso/Licencia "+estado,body2).then((r)=>{
        console.log(r);
      });
    }
    this.solicitudescada.splice(k,1);
  }










  async aceptarcada(k)
  {
    //console.log(this.solicitudes[i].fechas);
    // let hora_inicio=this.solicitudes[k].horainicio;
    // let hora_fin=this.solicitudes[k].horafin;
    // let fics=[];
    // for(let dia of this.solicitudes[k].fechas.split(","))
    // {
    //     let fecha=this.solicitudes[k].fechainicio.substr(0,4);
    //     fecha+="-"+dia.substr(3,2)+"-"+dia.substr(0,2);
    //     let fecha_inicio=fecha;
    //     let fecha_fin=fecha;
    //     if (hora_inicio!=null && hora_fin!=null)
    //     {
    //        fecha_inicio+=" "+hora_inicio;
    //        fecha_fin+=" "+hora_fin;
    //     }
    //     //console.log(fecha_inicio,fecha_fin);
    //     let fic={inicio:fecha_inicio,fin:fecha_fin,idempleado:this.solicitudes[k].idEmpleado,
    //              tipo:'permiso',detalle:this.solicitudes[k].solicitud};
    //     fics.push(fic);
    // }
    // console.log(fics);
    // await this.httpClient.post<Respuesta>(this.gds.urlBaseDL+'horario',fics).toPromise().then((r)=>{
    //   console.log(r);
    // });
    this.procesarSolicitudcada(k,"ACEPTADA");
    //this.loadDisfrutados();
  }

  rechazarcada(k)
  {
    this.procesarSolicitudcada(k,"RECHAZADA");
  }











}
